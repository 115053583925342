<template id="login">
  <div class="login d-flex justify-content-center align-content-center">
    <form class="form-signin">
      <div class="text-center mb-4">
        <img class="mb-4" src="../assets/logo.svg" alt="" width="140" height="140">
        <h1 class="h3 mb-3 font-weight-normal">Login to Continue</h1>
      </div>

      <div class="form-label-group">
        <input type="tel" id="inputMobile" class="form-control"
               v-model="mobile"
               placeholder="Mobile Number" required autofocus :disabled="status === 'OTP_REQUESTED' || status === 'RESEND'">
        <label for="inputMobile">Mobile Number</label>
      </div>

      <div class="form-label-group" v-if="status === 'OTP_REQUESTED'  || status === 'RESEND'">
        <input type="number" id="inputOTP" class="form-control" placeholder="OTP" required v-model="otp">
        <label for="inputOTP">OTP</label>
      </div>

      <b-alert variant="info" v-if="status === 'OTP_REQUESTED'" show  class="d-flex align-content-center align-items-center ml-2 flex-column justify-content-center">
        <span class="text-center mb-1">OTP has been sent to your SMS</span>
        <span class="text-center"><font-awesome-icon :icon="['fas', 'spinner']" spin class="mr-1"></font-awesome-icon> {{timeout}} Seconds</span>
      </b-alert>

      <b-alert variant="warning" v-if="customerNotRegistered" show>
        <div class="d-flex align-content-center align-items-center justify-content-start">

          <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="2x"></font-awesome-icon>
          <div class="d-flex align-content-center align-items-center ml-2">
            Account Not Found, Could you please register first.
          </div>
        </div>
      </b-alert>

      <b-alert variant="warning" v-if="invalidOTP" show>
        <div class="d-flex align-content-center align-items-center justify-content-start">

          <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="2x"></font-awesome-icon>
          <div class="d-flex align-content-center align-items-center ml-2">
            Invalid OTP, Kindly Enter correct OTP
          </div>
        </div>
      </b-alert>


      <button class="btn btn-lg mt-3 btn-primary btn-block" type="button" v-if="status === 'INIT' || status === 'RESEND'"
              @click.prevent="getOTP()">
        <font-awesome-icon :icon="['far', 'key']"></font-awesome-icon>
        <span class="ml-1" v-if="status==='INIT'">Get OTP</span>
        <span class="ml-1" v-else>Resend OTP</span>
      </button>

      <button class="btn btn-lg mt-3 btn-primary btn-block" type="button" v-if="status === 'OTP_REQUESTED' || status === 'RESEND'"
              @click.prevent="signIn()">
        <font-awesome-icon :icon="['far', 'sign-in']"></font-awesome-icon>
        Sign in
      </button>

      <hr>
      <b-card>
        <h3 class="text-center mb-3">Customer Care</h3>
        <a href="tel:+916362033034" class="d-flex justify-content-center align-items-center align-content-center" style="font-size: 1.5em">
          <font-awesome-icon :icon="['fas', 'phone']"></font-awesome-icon>
          <span class="ml-2">+91 63 62 033 034</span>
        </a>
      </b-card>
    </form>

  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: "login",
  data: () => {
    return {
      status: 'INIT',
      mobile: '',
      otp: '',
      customerNotRegistered: false,
      invalidOTP: false,
      intervalKey: null,
      timeout: -1
    }
  },
  created: function () {

  },
  methods: {
    async getOTP() {
      try {
        let postData = {
          mobile: this.mobile
        }
        for (let envKey in process.env) {
          console.log('env', envKey, process.env[envKey])
        }
        let resp = await axios.post(`/auth/login/otp?env=${process.env.NODE_ENV}`, postData)
        if (resp.data.status === false) {
          if (resp.data.customer === false) {
            this.customerNotRegistered = true
            return
          }
        }
        this.customerNotRegistered = false
        this.status = 'OTP_REQUESTED'
        this.timeout = 30
        this.intervalKey = setInterval(() => {
          this.timeout--
          console.log('wait', this.timeout)
          if(this.timeout === 0){
            clearInterval(this.intervalKey)
            this.status = 'RESEND'
          }
        }, 1000)
      } catch (e) {
        console.error("error ion loading otp", e)
      }
    },
    async signIn() {
      try {
        let postData = {'mobile': this.mobile, 'otp': this.otp}

        try {

          let res = await axios.post('/auth/login/validate', postData)
          let at = res.data['at']

          this.$store.commit("loggedIn", at)
          await this.$store.dispatch("loadME")
          await this.$store.dispatch("loadCategories")
          await this.$store.dispatch("loadProducts")

          if (this.$store.state.userRole === 'ADMIN') {
            await this.$store.dispatch("loadTaxPct")
          }
          let role = this.$store.state.userRole
          if (this.intervalKey)
            clearInterval(this.intervalKey)

          if (role === 'DELIVERY_PERSON') {
            await this.$router.push('/delivery/orders')
          } else if (role === 'STORE_EMPLOYEE') {
            await this.$router.push('/store/new-order')
          } else if (role === 'ADMIN') {
            await this.$router.push('/admin/orders')
          }
        } catch (ex) {
          if (ex.response.status === 400) {
            this.invalidOTP = true
          }
        }
      } catch (e) {
        console.error("error in login/state loading", e)
      }

    }
  }
}

</script>
<style scoped>
.login {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: .75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777;
}

</style>