<template functional>
  <div class="d-flex align-content-center align-items-center">
    <customer-avatar :customer="props.customer" size="2em"></customer-avatar>
    <div class="ml-2">
      {{ props.customer.name }} - {{ props.customer.mobile }} <br/>
      {{ props.customer.deliveryArea.name }}
    </div>
  </div>
</template>
<script>

export default {
  functional: true,
  name: 'Customer',
  props: {
    customer: Object
  }
}
</script>