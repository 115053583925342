<template>

  <b-modal id="modal-cust-addr" :title="title" hide-footer size="xl">
    <section>


      <div class="d-flex justify-content-between mb-3 align-items-center">
        <h5 class="">Address List for {{ newCustomer.name }}</h5>
        <b-button variant="primary" @click="newAddr" size="sm">
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </b-button>

      </div>
      <hr>

      <div class="list-group list-group-flush">
        <div class="list-group-item d-flex flex-column flex-md-row flex-wrap align-content-center align-items-center" v-for="(a, idx) of addresses"
             :key="idx">
          <b-form-group class="mr-md-1" label="Door #">
            <b-form-input v-model="a.door"></b-form-input>
          </b-form-group>
          <b-form-group class="mr-md-1" label="Apartment/Area">
            <b-form-input v-model="a.apartment"></b-form-input>
          </b-form-group>
          <b-form-group class="mr-md-1" label="Address">
            <b-form-input v-model="a.address"></b-form-input>
          </b-form-group>
          <b-form-group class="mr-md-1" label="Pincode">
            <b-form-input v-model="a.pinCode"></b-form-input>
          </b-form-group>
          <b-form-group class="mr-md-1" label="Mobile">
            <b-form-input v-model="a.mobile" type="tel"></b-form-input>
          </b-form-group>
          <b-form-group class="mr-md-1" label="City">
            <b-form-input v-model="a.city"></b-form-input>
          </b-form-group>
          <b-form-group class="mr-md-1" label="Name of Person">
            <b-form-input v-model="a.person"></b-form-input>
          </b-form-group>
          <b-form-group class="mr-md-1" label="Landmark">
            <b-form-input v-model="a.landmark"></b-form-input>
          </b-form-group>

        <div class="d-inline mt-3">
            <b-button size="lg" variant="success" @click="saveAddr(a)">
              <font-awesome-icon icon="save"></font-awesome-icon>
              Save
            </b-button>
        </div>
        </div>
      </div>
    </section>
  </b-modal>

</template>

<script>
import axios from 'axios'

export default {
  name: 'AddressEdit',
  data: () => ({
    addresses: [],
    newCustomer: {
      name: ''
    }
  }),
  async created () {

  },
  watch: {
    async editAddr (customerId, oc) {

      customerId = customerId.split(':')[0]
      console.log(`editaddress for ${customerId} from ${oc}`)
      let json = await axios.get(`/api/admin/customers/${customerId}`)
      this.newCustomer = json.data
      this.addresses = (await axios.get(`/api/admin/address?customer_id=${customerId}`)).data
      await this.editCustomerAddress(customerId)
    }
  },
  computed: {
    title() {
      return `Edit Addresses for ${this.newCustomer.name}`
    },
    editAddr () {
      return this.$store.state.editAddress
    }
  },
  methods: {
    async editCustomerAddress (customerId) {
      this.addresses = (await axios.get(`/api/admin/address?customer_id=${customerId}`)).data
      this.$bvModal.show('modal-cust-addr')
    },
    async newAddr () {
      this.addresses.unshift({customer: {id: this.newCustomer.id}, id: null})
    },
    async saveAddr (a) {
      console.log('saveAddr', a)
      let a2 = Object.assign({}, a)
      await axios.post('/api/admin/address?customer_id=' + this.newCustomer.id, a2)
      this.$bvToast.toast('Address Saved', {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'success'
      })
      this.addresses = (await axios.get(`/api/admin/address?customer_id=${this.newCustomer.id}`)).data
      this.$bvModal.hide('modal-cust-addr')
      await this.$store.dispatch('addressUpdated', {id: this.newCustomer.id})
    },
  }
}
</script>

<style scoped>

</style>