<template>
  <b-modal id="modal-1" title="New Order" size="xl" @ok="save" :ok-disabled="!order.brandId">
    <b-form>
      <b-form-group label="Apartment"
                    v-if="order.id == null">
        <v-select v-model="order.deliveryArea.id" :options="$store.state.allAreas"
                  :reduce="p => p.id"
                  label="displayName"></v-select>
      </b-form-group>

      <b-form-group label="Customer"
                    v-if="order.id == null">
        <v-select v-model="order.customer.id"
                  @search="searchCustomers"
                  :options="customers"
                  :reduce="p => p.id"
                  @input="loadAddress"
                  label="displayName"></v-select>

      </b-form-group>
      <b-form-group label="Address"
                    v-if="order.id == null">
        <v-select v-model="order.address.id" :options="addresses"
                  :reduce="p => p.id"
                  label="displayAddress"></v-select>

      </b-form-group>
      <!-- todo: add a new address from here-->
      <b-form-group
          v-if="order.id !== null" label="Customer">
        <b-form-input v-model="order.customer.name" placeholder="" disabled></b-form-input>

      </b-form-group>

      <b-form-group
          v-if="order.id !== null" label="Address">
        <b-form-input v-model="order.address.address" placeholder="" disabled></b-form-input>
      </b-form-group>

      <div class="row row-cols-1 row-cols-md-2">

        <div class="col">
          <b-form-group label="Mode Of Shipment">
            <b-form-select v-model="order.modeOfShipment" placeholder=""
                           :options="modeOfShipments"></b-form-select>
            <b-form-text id="input-live-help">Only WEFAST will send "do not pay cash" message</b-form-text>

          </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="Brand">
            <b-form-select v-model="order.brandId"
                           placeholder=""
                           :options="$store.state.brands"
                           value-field="id" text-field="name"
            ></b-form-select>

          </b-form-group>
        </div>
      </div>


      <div class="row row-cols-1 row-cols-md-2">
        <div class="col">
          <b-form-group label="Customer Notes">
            <b-form-textarea v-model="order.orderNote" placeholder="Notes (visible to customer)"></b-form-textarea>
          </b-form-group>
        </div>
        <div class="col">

          <b-form-group label="Admin Notes">
            <b-form-textarea v-model="order.adminNote"
                             placeholder="Notes (visible only to admin)"></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <b-form-checkbox switch v-model="order.notifyCustomer">Notify Customer?</b-form-checkbox>
      <b-form-checkbox switch v-model="order.sendReviewLink">Send Review Link?</b-form-checkbox>
      <b-form-checkbox switch v-model="order.sendPaymentLink">Send Payment Link?</b-form-checkbox>
      <b-form-checkbox switch v-model="order.paidAlready">Already Paid?</b-form-checkbox>

      <div class="list-group mb-3 mt-3">
        <div class="list-group-item d-flex justify-content-between">
          <span>Samples/Adhoc</span>
          <button class="btn btn-sm btn-info"
                  @click.prevent="order.samples.unshift({product:'', qty: '', price: 0})">
            <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
          </button>
        </div>
        <div class="list-group-item flex-column flex-md-row d-flex justify-content-between"
             v-for="(sam, idx) of order.samples" :key="idx">
          <b-form-input v-model="sam.product" class="mr-md-3 mt-3" placeholder="Product"></b-form-input>
          <b-form-textarea v-model="sam.qty" class="mr-md-3 mt-3" placeholder="Qty"
                           style="min-width: 100px"></b-form-textarea>
          <b-form-input v-model="sam.price" type="number" placeholder="Amount" style="min-width: 100px"
                        @input="updateLi()"
                        class="mr-3 mt-3" no-wheel></b-form-input>
          <div class="d-flex justify-content-end mt-3">
            <button class="btn btn-sm btn-danger" @click.prevent="order.samples.splice(idx, 1)">
              <font-awesome-icon :icon="['far','times']"></font-awesome-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between">
          <span>Items</span>
          <button class="btn btn-sm btn-info"
                  @click.prevent="order.items.unshift({product:{}, qty: 1, variant: {}})">
            <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
          </button>
        </div>
        <div class="list-group-item d-flex  flex-column flex-md-row flex-wrap"
             v-for="(li, index) of order.items" v-bind:key="index">

          <div style="min-width: 200px" class="mr-md-2 flex-grow-1">
            <b-form-group label="Product">
              <v-select :options="allProducts"
                        :reduce="p => p.id"
                        @input="selectFirstVariant(li)" v-model="li.product.id" label="name"></v-select>

            </b-form-group>
          </div>
          <div style="min-width: 150px" class="mr-md-2">
            <b-form-group label="variant" class="mr-md-2 mb-2 mb-md-0">
              <b-form-select v-model="li.variant.id"
                             :options="variants(li)"
                             value-field="id"
                             text-field="displayName"
                             @input="updateLi"
              ></b-form-select>
            </b-form-group>
          </div>


          <div style="min-width: 80px" class="mr-md-2">
            <b-form-group label="Qty">
              <input type="number" v-model="li.qty" class="form-control" @input="updateLi()"/>
            </b-form-group>
          </div>

          <div class="d-flex  justify-content-end">
            <div class="mt-md-4 pt-md-1 ">
              <button class="btn btn-sm btn-danger" @click.prevent="order.items.splice(index, 1)">
                <font-awesome-icon :icon="['far','times']"></font-awesome-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row justify-content-end mt-3">

        <b-form-group label="Item Amount" class="mr-md-3">
          <b-form-input v-model="itemValue" type="number" disabled/>
        </b-form-group>


        <b-form-group label="Discount Code"
                      description="Applied with out minimum order validation."
                      class="mr-md-3">
          <b-form-select v-model="order.discountCode" :options="discounts"
                         @input="calcDiscount"
                         value-field="code" text-field="code"/>
        </b-form-group>
        <b-form-group label="Discount"
                      description="Auto Calculated if Code is Selected"
        >
          <b-form-input v-model="order.discount" type="number" @input="updateLi()"/>
        </b-form-group>

      </div>

      <div class="d-flex flex-column flex-md-row justify-content-end mt-3">

        <b-form-group label="Shipping Charges" class="mr-md-3">
          <b-form-input v-model="order.shipping" type="number" @input="updateLi()"/>
        </b-form-group>

        <b-form-group label="Final Amount">
          <b-form-input v-model="finalItemValue" type="number" disabled/>
        </b-form-group>
      </div>

    </b-form>
  </b-modal>

</template>

<script>
import axios from 'axios'
import {NewOrder, NewDeliveryArea} from '@/util'
import _ from 'lodash'

export default {
  name: 'OrderEdit',
  data: () => {
    return {
      addresses: [],
      area: null,
      modeOfShipments: ['WEFAST', 'SHIPROCKET', 'SELF', 'AMAZON'],
      order: NewOrder(NewDeliveryArea(), "")
    }
  },
  computed: {
    discounts() {
      return this.$store.state.discounts
    },
    orderEdit() {
      return this.$store.state.editOrder
    },
    allProducts() {
      return this.$store.state.categories.flatMap(c => c.products)
    },

    customers() {
      return this.$store.state.searchCustomers
    },
    itemValue() {
      return _.sumBy(this.order.items.map(o => {
        let variants = this.variants(o)
        if (variants && o.variant && o.variant.id) {
          let v = variants.filter(v => v.id === o.variant.id)[0]
          if (v.offerPrice != null && v.offerPrice > 0.0) return v.offerPrice * o.qty
          return v.price * o.qty
        } else {
          return 0
        }
      })) + _.sumBy(this.order.samples.map(s => +s.price))
    },
    finalItemValue() {
      return this.itemValue - this.order.discount + (+this.order.shipping)
    },
  },
  watch: {
    async orderEdit(newId, oldId) {
      setTimeout(async () => {
        console.log(`order with ${newId} is sent for edit, old = ${oldId}, order = ${JSON.stringify(this.$store.state.order)}`)

        await this.$store.dispatch('loadDiscounts')
        await this.$store.dispatch('loadAreas')

        this.order = Object.assign({}, this.$store.state.order)

        if (this.order.customer.id) {
          console.log(`a customer is pre-selected for a new order ${this.order.customer.id}`)
          await this.$store.dispatch('loadSelectedCustomer', this.order.customer.id)
          await this.loadAddress()
        }
        this.$bvModal.show('modal-1')
      }, 300)
    }
  },
  methods: {

    async calcDiscount() {
      setTimeout(async () => {
        if(this.order.discountCode) {
          let d = (await axios.post(`/api/admin/discount/apply?value=${this.itemValue}&code=${this.order.discountCode}`)).data
          this.order.discount = d.discount
        }
      }, 100)
    },
    async loadAddress() {
      let r = await axios.get('/api/admin/address?customer_id=' + this.order.customer.id)
      let orders = (await axios.get(`/api/admin/orders?customerId=${this.order.customer.id}&time=120`)).data.orders
      this.addresses = r.data
      if (this.addresses.length > 0) {
        this.order.address = {
          id: this.addresses[0].id,
          address: this.addresses[0].address
        }
      }
      let fc = this.customers.filter(c => c.id === this.order.customer.id)
      //send review link only if > 1 order is delivered
      let co = orders.filter(o => o.orderStatus === 'DELIVERED').length
      this.order.sendReviewLink = co > 1 && fc.length > 0 && !fc[0].reviewDone
      console.log('sendReviewLink?', this.order.customer.id, orders.length, co, this.order.sendReviewLink)
    },

    async searchCustomers(search, loading) {
      if (search.length > 2) {
        loading(true)
        await this.$store.dispatch('searchCustomer', {search: search, area: this.area})
        loading(false)
      }
    },
    async save() {
      let order = Object.assign({}, this.order)
      order['shipping'] = +this.order.shipping
      let o = (await axios.post('/api/admin/orders', order)).data

      await this.$store.dispatch('orderUpdated', {
        id: `${this.order.id || o.id || 'NEW'}${new Date().getTime()}`,
        status: 'Created',
        orderNum: o.orderNum
      })

    },
    async updateLi() {
      await this.calcDiscount()
    },
    selectFirstVariant(li) {
      let variants = this.variants(li)
      if (variants.length > 0) {
        li.variant.id = variants[0].id
        li.variant.name = variants[0].name
      }
    },
    variants(li) {
      if (li.product && li.product.id) {
        let products = this.$store.state.products
            .filter(p => p.id === li.product.id)
        if (products.length === 0) return []

        return products[0].variants
      }
      return []
    },
  }
}
</script>

<style scoped>

</style>