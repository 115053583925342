<template id="app-frame">
  <header class="sticky-md-top fixed-bottom mb-md-3 app-header">
    <b-navbar toggleable="lg" type="dark" variant="dark" class="custom-navbar" sticky>
      <b-navbar-brand to="/">
        <span class="d-inline">Admin Console</span>
      </b-navbar-brand>

      <div class="d-flex flex-fill justify-content-end d-md-none">
        <b-button v-b-modal.search-panel variant="outline-light" class="" style="margin-right: 10px">
          <font-awesome-icon icon="search" size="2x"></font-awesome-icon>
        </b-button>
        <b-button @click="showMessages" v-if="isAdmin" variant="outline-light" class="" style="margin-right: 10px">
          <font-awesome-layers class="fa-2x">
            <font-awesome-icon fixed-width icon="envelope"/>
            <font-awesome-layers-text counter :value="unreadMessages" position="top-right"/>
          </font-awesome-layers>
        </b-button>
      </div>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/" v-if="isLoggedIn">
            <font-awesome-icon fixed-width icon="home"></font-awesome-icon>
            Home
          </b-nav-item>
          <b-nav-item-dropdown v-if="isAdmin">
            <template v-slot:button-content>
              <font-awesome-icon fixed-width icon="print"></font-awesome-icon>
              Print
            </template>
            <b-dropdown-item to="/admin/product-print">
              <font-awesome-icon fixed-width icon="print"></font-awesome-icon>
              Package Cover
            </b-dropdown-item>
            <b-dropdown-item to="/admin/ing-print">
              <font-awesome-icon fixed-width icon="print-search"></font-awesome-icon>
              Labels
            </b-dropdown-item>
            <b-dropdown-item to="/admin/product-list-print">
              <font-awesome-icon fixed-width icon="album-collection"></font-awesome-icon>
              Product List
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item to="/admin/leads" v-if="isAdmin" variant="outline" class="d-none d-md-block">
            <font-awesome-icon fixed-width icon="user"/>
            Leads
          </b-nav-item>
          <b-nav-item to="/admin/messages" v-if="isAdmin" variant="outline" class="d-none d-md-block">
            <font-awesome-layers>
              <font-awesome-icon fixed-width icon="envelope"/>
              <font-awesome-layers-text counter :value="unreadMessages" position="top-right"/>
            </font-awesome-layers>
            Messages
          </b-nav-item>
          <b-nav-form class="d-none d-md-flex">
            <search-form></search-form>
          </b-nav-form>
          <b-nav-item-dropdown v-if="isAdmin">
            <template v-slot:button-content>
              <font-awesome-icon fixed-width icon="user-ninja"></font-awesome-icon>
              Orders
            </template>
            <b-dropdown-item to="/admin/repeat-orders">
              <font-awesome-icon fixed-width icon="sync"></font-awesome-icon>
              Repeat Orders
            </b-dropdown-item>
            <b-dropdown-item to="/admin/orders">
              <font-awesome-icon fixed-width icon="shopping-cart"></font-awesome-icon>
              Orders
            </b-dropdown-item>
            <b-dropdown-item to="/admin/returns">
              <font-awesome-icon fixed-width icon="reply-all"></font-awesome-icon>
              Returns
            </b-dropdown-item>

          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="isAdmin">
            <template v-slot:button-content>
              <font-awesome-icon fixed-width icon="user-ninja"></font-awesome-icon>
              Admin
            </template>

            <b-dropdown-item to="/admin/brands">
              <font-awesome-icon fixed-width icon="phone-office"></font-awesome-icon>
              Brands
            </b-dropdown-item>


            <b-dropdown-item to="/admin/areas">
              <font-awesome-icon fixed-width icon="building"></font-awesome-icon>
              Delivery Areas
            </b-dropdown-item>
            <b-dropdown-item to="/admin/products">
              <font-awesome-icon fixed-width icon="barcode"></font-awesome-icon>
              Products
            </b-dropdown-item>

            <b-dropdown-item to="/admin/combos">
              <font-awesome-icon fixed-width icon="barcode"></font-awesome-icon>
              Combos
            </b-dropdown-item>

            <b-dropdown-item to="/admin/stock">
              <font-awesome-icon fixed-width icon="barcode"></font-awesome-icon>
              Stock
            </b-dropdown-item>

            <b-dropdown-item to="/admin/categories">
              <font-awesome-icon fixed-width icon="barcode-alt"></font-awesome-icon>
              Categories
            </b-dropdown-item>
            <b-dropdown-item to="/admin/deliveries">
              <font-awesome-icon fixed-width icon="truck"></font-awesome-icon>
              Deliveries
            </b-dropdown-item>
            <b-dropdown-item to="/admin/users">
              <font-awesome-icon fixed-width icon="users"></font-awesome-icon>
              Users
            </b-dropdown-item>
            <b-dropdown-item to="/admin/config">
              <font-awesome-icon fixed-width icon="cogs"></font-awesome-icon>
              Config
            </b-dropdown-item>
            <b-dropdown-item to="/admin/expenses">
              <font-awesome-icon fixed-width icon="money-check"></font-awesome-icon>
              Expenses
            </b-dropdown-item>
            <b-dropdown-item to="/admin/payments">
              <font-awesome-icon fixed-width icon="wallet"></font-awesome-icon>
              Payments
            </b-dropdown-item>
            <b-dropdown-item to="/admin/blog">
              <font-awesome-icon fixed-width icon="blog"></font-awesome-icon>
              Blog Content
            </b-dropdown-item>
            <b-dropdown-item to="/super-admin">
              <font-awesome-icon fixed-width icon="user-shield"></font-awesome-icon>
              Super-Admin
            </b-dropdown-item>
            <b-dropdown-item to="/admin/tools">
              <font-awesome-icon fixed-width icon="tools"></font-awesome-icon>
              Tools
            </b-dropdown-item>
            <b-dropdown-item to="/admin/discounts">
              <font-awesome-icon fixed-width icon="money-check"></font-awesome-icon>
              Discounts
            </b-dropdown-item>
            <b-dropdown-item to="/admin/partners">
              <font-awesome-icon fixed-width icon="user-clock"></font-awesome-icon>
              Partners
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="isAdmin">
            <template v-slot:button-content>
              <font-awesome-icon fixed-width icon="user-ninja"></font-awesome-icon>
              Customers
            </template>



            <b-dropdown-item to="/admin/customers">
              <font-awesome-icon fixed-width icon="users-crown"></font-awesome-icon>
              Customers
            </b-dropdown-item>

            <b-dropdown-item to="/admin/leads">
              <font-awesome-icon fixed-width icon="user"></font-awesome-icon>
              Leads
            </b-dropdown-item>

          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="isStore">
            <template v-slot:button-content>
              <font-awesome-icon fixed-width icon="store"></font-awesome-icon>
              Store
            </template>
            <b-dropdown-item to="/store/new-order">
              <font-awesome-icon fixed-width icon="cart-plus"></font-awesome-icon>
              New Order
            </b-dropdown-item>
            <b-dropdown-item to="/store/orders">
              <font-awesome-icon fixed-width icon="shopping-cart"></font-awesome-icon>
              Orders
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- Right aligned nav items -->
        </b-navbar-nav>

        <b-navbar-nav class="">


          <b-nav-item-dropdown right v-if="$store.state.isLoggedIn">
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <b-img style="max-height: 28px" rounded="circle" v-if="me.profilePicUrl" :src="me.profilePicUrl"></b-img>
              <font-awesome-icon v-else fixed-width icon="user-circle"></font-awesome-icon>
              <span class="ml-1"><em>User</em></span>
            </template>
            <b-dropdown-item to="/logout">
              <font-awesome-icon fixed-width icon="sign-out"></font-awesome-icon>
              Sign Out
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>

    </b-navbar>

    <b-modal id="search-panel" hide-footer hide-header>
      <div class="d-block d-md-none my-3 py-3 search-panel">
        <search-form size="lg" @clicked="onClickSearch" css=""></search-form>
      </div>
    </b-modal>
  </header>
</template>
<script>
import { sumBy } from 'lodash/math'
import SearchForm from '@/components/SearchForm'

export default {
  name: 'AppHeader',
  components: {SearchForm},
  data: function () {
    return {}
  },
  computed: {
    unreadMessages () {
      return sumBy(this.$store.state.threads, 'unread')
    },
    isLoggedIn: function () {
      return this.$store.state.isLoggedIn
    },
    isAdmin: function () {
      return this.$store.state.userRole === 'ADMIN'
    },
    isStore: function () {
      return this.$store.state.userRole === 'ADMIN' ||
          this.$store.state.userRole === 'STORE_EMPLOYEE'
    },
    me: function () {
      return this.$store.state.me
    },
  },
  async created () {
    if (this.$store.state.userRole === 'ADMIN')
      await this.$store.dispatch('loadThreads')
  },
  methods: {
    async onClickSearch () {
      setTimeout(() => {
        this.$bvModal.hide('search-panel')
      }, 100)
    },
    async showMessages () {
      await this.$router.push('/admin/messages')
    },

  }
}
</script>
<style scoped>
header {
  font-size: 18px;
}

h4 {
  padding-bottom: 10px;
}

@media (max-width: 576px) {

}

@media (min-width: 576px) {
  .sticky-md-top {

    position: sticky !important;
    top: 0;
    z-index: 1020;
  }
}
</style>