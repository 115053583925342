import Vue from 'vue'
import Router from 'vue-router'

import HelloWorld from '@/components/hello-world'
import Login from '@/components/login'
import Logout from '@/components/logout'

const ProductView = () => import(/* webpackChunkName: "admin1" */ '@/components/ProductView')
const OrderView = () => import(/* webpackChunkName: "admin1" */ '@/components/OrderView')
const AdminAreas = () => import(/* webpackChunkName: "admin1" */ '@/components/admin-areas')
const Followup = () => import(/* webpackChunkName: "admin1" */ '@/components/admin-followup')
const AdminCategories = () => import(/* webpackChunkName: "admin1" */ '@/components/admin-categories')
const AdminCustomers = () => import(/* webpackChunkName: "admin2" */ '@/components/admin-customers')
const AdminDeliveries = () => import(/* webpackChunkName: "admin2" */ '@/components/admin-deliveries')
const AdminOrders = () => import(/* webpackChunkName: "admin2" */ '@/components/admin-orders')
const AdminPayments = () => import(/* webpackChunkName: "admin2" */ '@/components/admin-payments')
const AdminProducts = () => import(/* webpackChunkName: "admin2" */ '@/components/admin-products')
const AdminUsers = () => import(/* webpackChunkName: "admin3" */ '@/components/admin-users')
const Payments = () => import(/* webpackChunkName: "admin3" */ '@/components/payments')
const AdminExpenses = () => import(/* webpackChunkName: "admin3" */ '@/components/admin-expenses')
const AdminCustomerDetail = () => import(/* webpackChunkName: "admin3" */ '@/components/admin-customer-detail')
const StoreNewOrder = () => import(/* webpackChunkName: "admin3" */ '@/components/store-new-order')
const StoreOrders = () => import(/* webpackChunkName: "admin4" */ '@/components/store-orders')
const AdminConfig = () => import(/* webpackChunkName: "admin4" */ '@/components/admin-config')
const NewProducts = () => import(/* webpackChunkName: "admin4" */ '../components/NewProducts')
const AdminMessages = () => import(/* webpackChunkName: "admin4" */ '@/components/admin-messages')
const AdminMessagesDetail = () => import(/* webpackChunkName: "admin4" */ '@/components/admin-message-detail')
const AdminReturns = () => import(/* webpackChunkName: "admin5" */ '@/components/admin-returns')
const DeliveryHome = () => import(/* webpackChunkName: "admin5" */ '@/components/delivery/home')
const SuperAdmin = () => import(/* webpackChunkName: "admin5" */ '@/components/super-admin')
const Dashboard = () => import(/* webpackChunkName: "admin5" */ '@/components/dashboard')
const ProductPrint = () => import(/* webpackChunkName: "admin5" */ '@/components/product-print')
const ProductListPrint = () => import(/* webpackChunkName: "admin6" */ '@/components/print-products-list')
const IngPrint = () => import(/* webpackChunkName: "admin6" */ '@/components/IngPrint')
const RepeatOrders = () => import(/* webpackChunkName: "admin6" */ '@/components/admin/RepeatOrders')
const BlogContent = () => import(/* webpackChunkName: "admin6" */ '@/components/admin/BlogContent')
const Tools = () => import(/* webpackChunkName: "admin6" */ '@/components/admin/Tools')
const Discount = () => import(/* webpackChunkName: "admin6" */ '@/components/admin/Discounts')
const Partner = () => import(/* webpackChunkName: "admin6" */ '@/components/admin/Partner')
const Brand = () => import(/* webpackChunkName: "admin6" */ '@/components/admin/Brands')
const Stock = () => import(/* webpackChunkName: "admin6" */ '@/components/admin-stock')
const Combos = () => import(/* webpackChunkName: "admin6" */ '@/components/admin/Combos')

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: HelloWorld
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/admin/product-print',
      name: 'product-print',
      component: ProductPrint
    },
    {
      path: '/admin/product-list-print',
      name: 'product-list-print',
      component: ProductListPrint
    },
    {
      path: '/admin/ing-print',
      name: 'ing-print',
      component: IngPrint
    },
    {
      path: '/admin/product-print/:productId',
      name: 'product-print-1',
      component: ProductPrint
    },
    {
      path: '/delivery/orders',
      name: 'delivery-home',
      component: DeliveryHome
    },
    {
      path: '/admin/leads',
      name: 'leads',
      component: Followup
    },
    {
      path: '/admin/stock',
      name: 'stock',
      component: Stock
    },
    {
      path: '/admin/brands',
      name: 'brands',
      component: Brand
    },
    {
      path: '/admin/dashboard',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/admin/blog',
      name: 'blog',
      component: BlogContent
    },
    {
      path: '/admin/tools',
      name: 'tools',
      component: Tools
    },
    {
      path: '/admin/discounts',
      name: 'discounts',
      component: Discount
    },
    {
      path: '/admin/combos',
      name: 'combos',
      component: Combos
    },
    {
      path: '/admin/expenses',
      name: 'expenses',
      component: AdminExpenses
    },
    {
      path: '/admin/messages/:id',
      name: 'admin-messages-detail',
      component: AdminMessagesDetail
    },
    {
      path: '/admin/messages',
      name: 'admin-messages',
      component: AdminMessages
    },
    {
      path: '/admin/returns',
      name: 'admin-returns',
      component: AdminReturns
    },
    {
      path: '/super-admin',
      name: 'super-admin',
      component: SuperAdmin
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    },
    {
      path: '/admin/orders',
      name: 'admin-orders',
      component: AdminOrders
    },
    {
      path: '/admin/repeat-orders',
      name: 'admin-repeat-orders',
      component: RepeatOrders
    },
    {
      path: '/admin/orders/:type',
      name: 'admin-orders-type',
      component: AdminOrders
    },
    {
      path: '/admin/orders/new-order/:customer/:address',
      name: 'admin-orders-new-order',
      component: AdminOrders
    },
    {
      path: '/admin/config',
      name: 'admin-config',
      component: AdminConfig
    },
    {
      path: '/admin/customer/:id',
      name: 'customer-detail',
      component: AdminCustomerDetail
    },
    {
      path: '/admin/customers',
      name: 'customers',
      component: AdminCustomers
    },
    {
      path: '/admin/product/:id',
      name: 'product-view',
      component: ProductView
    },
    {
      path: '/admin/order/:id',
      name: 'order-view',
      component: OrderView
    },
    {
      path: '/admin/areas',
      name: 'areas',
      component: AdminAreas
    },
    {
      path: '/admin/categories',
      name: 'categories',
      component: AdminCategories
    },
    {
      path: '/admin/deliveries',
      name: 'deliveries',
      component: AdminDeliveries
    },
    {
      path: '/admin/payments',
      name: 'admin-payments',
      component: AdminPayments
    },
    {
      path: '/admin/products',
      name: 'admin-products',
      component: AdminProducts
    },
    {
      path: '/admin/users',
      name: 'admin-users',
      component: AdminUsers
    },
    {
      path: '/admin/partners',
      name: 'admin-partners',
      component: Partner
    },
    {
      path: '/payments',
      name: 'payments',
      component: Payments
    },
    {
      path: '/store/new-order',
      name: 'store-new-order',
      component: StoreNewOrder
    },
    {
      path: '/store/edit-order/:id',
      name: 'store-edit-order',
      component: StoreNewOrder
    },
    {
      path: '/store/orders',
      name: 'store-orders',
      component: StoreOrders
    },
    {
      path: '/newly-launched',
      name: 'newly-launched',
      component: NewProducts
    }
  ],
  mode: 'history'
})