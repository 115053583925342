<template id="rupee">
    <span class="rupee">
        &#8377;
        <slot></slot>
    </span>
</template>
<script>
export default {
  name: 'rupee'
}
</script>
<style>

</style>