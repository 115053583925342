import Vue from 'vue'
import {
    BootstrapVue,
    FormPlugin,
    ModalPlugin,
} from 'bootstrap-vue'
import axios from 'axios'
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/loading.css'
import '@/assets/app.css'
import '@/assets/bootstrap-dark.css'
import '@/assets/vue-select-dark.css'
import {loading} from './util'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {far} from '@fortawesome/pro-regular-svg-icons'
import {fas} from '@fortawesome/pro-solid-svg-icons'
import './registerServiceWorker'
import 'leaflet/dist/leaflet.css'
import {Icon} from 'leaflet'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import './Freeh521-normal'
import './trebuc-normal'
import './Chango-Regular-normal'
import './GothamSSm-Book-normal'

// in main.js
import VueSSE from 'vue-sse'
import Chart from 'chart.js'
import * as VueGoogleMaps from 'vue2-google-maps'
import CustomerAvatar from "@/components/customer-avatar.vue";
import ProductAvatar from "@/components/avatar/product-avatar.vue";

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyD25L7mhSIy6ZwIO6uxh-g_IcoXdVfHs1Y',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: false
})
Vue.component('google-map', VueGoogleMaps.Map);
Vue.component('google-marker', VueGoogleMaps.Marker);
Vue.component('google-autocomplete', VueGoogleMaps.Autocomplete);
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

library.add(fab)
library.add(far)
library.add(fas)

Vue.config.productionTip = false
Vue.use(FormPlugin, {
    'BFormFile': {
        'placeholder': '',
        'browseText': 'Select an Image'
    }
})
Vue.use(ModalPlugin, {
    'BModal': {
        'okTitleHtml': '&check; OK',
        'cancelTitleHtml': '&cross; Cancel',
        'cancelVariant': 'warning',
        'size': 'xl',
        'buttonSize': 'lg'
    }
})
Vue.component('v-select', vSelect)
Vue.use(BootstrapVue)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('customer-avatar', CustomerAvatar)
Vue.component('product-avatar', ProductAvatar)
Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', f)
    }
})

axios.defaults.baseURL = process.env.VUE_APP_URL

// OR specify custom defaults (described below)
Vue.use(VueSSE, {
    format: 'json',
})

let app = new Vue({
    el: '#app',
    router,
    store,
    components: {App},
    template: '<App/>'
})

axios.interceptors.request.use(function (config) {

    if (!config.headers['is-search']) {
        loading(true)
    } else {
        console.log('ignore search request')
    }
    return config
}, function (error) {
    // Do something with request error
    // loading(false)
    loading(false)
    console.error('interceptor', error)

    let errorMsg = 'Oops!, Something went wrong, could you reload and retry?'
    app.$bvToast.toast(errorMsg, {
        title: 'Oops',
        autoHideDelay: 5000,
        variant: 'info',
        solid: true,
        appendToast: false
    })
    return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
    //success will always have 2xx status codes
    if (!response.headers['is-search']) {
        loading(false)
    } else {
        console.log('ignore search response')
    }
    return response
}, (error) => {
    //you have to check for status code
    loading(false)
    if (error.response.status === 400 && error.response.config.url.indexOf('/auth/') === -1) {
        app.$bvToast.toast(`${error.response.data.title}`, {
            title: 'Oops!',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            appendToast: false
        })
    } else if (error.response.status > 499) {
        app.$bvToast.toast('Something went wrong, could you reload and retry?', {
            title: 'Oops!',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            appendToast: false
        })
    } else if (error.response.status === 401) {
        app.$bvToast.toast('Kindly Login to continue', {
            title: 'Oops!',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            appendToast: false
        })

        if (app.$route.path !== '/login') {
            console.log('Sending to Login Screen')
            app.$store.commit('loggedOut')
            app.$router.push('/login').then(() => {
                console.log('sent to login')
            }, err => {
                console.error('error in sending to login', err)
            })
        } else {
            console.log('Already in Login Screen')
        }
    }
    return Promise.reject(error)
})
Chart.scaleService.updateScaleDefaults('linear', {
    ticks: {
        min: 0
    }
});
