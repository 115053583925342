<template>
  <div :style="'width:'+size">
    <b-img-lazy v-if="hasImage()" :src="makeAvImg()"
                :size="size"
                fluid
                rounded="circle"
                :variant="makeAvVariant()"></b-img-lazy>
    <b-avatar :text="makeAvText()"
              v-else
              :size="size"
              :variant="makeAvVariant()"></b-avatar>
  </div>
</template>
<script>
export default {
  name: 'CustomerAvatar',
  props: ['customer', 'size'],
  methods: {
    makeAvText () {
      let c = this.customer || {name: '', profilePicUrl: ''}
      let t = c.name.split(' ').map(p => p.toUpperCase()[0]).filter((_, i) => i < 2).join('')
      return !c.profilePicUrl || c.profilePicUrl.length === 0 ? t : ''
    },
    makeAvImg () {
      let c = this.customer || {name: '', profilePicUrl: ''}
      if (!c.profilePicUrl || c.profilePicUrl.length === 0) {
        return ''
      } else {
        if (c.profilePicUrl.startsWith("http")) {
          return c.profilePicUrl
        }
        return `${process.env.VUE_APP_URL}/public/attachment?file=${c.profilePicUrl}`
      }
    },
    hasImage () {
      let c = this.customer || {name: '', profilePicUrl: ''}
      return !(!c.profilePicUrl || c.profilePicUrl.length === 0);

    },
    makeAvVariant () {
      let indx = Math.ceil(Math.random() * 10)
      return ['primary', 'secondary', 'info', 'success', 'warning', 'dark', 'light'][indx % 7]
    }
  }
}
</script>