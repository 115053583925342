<template>
  <div :style="'width:'+size">
    <b-img-lazy v-if="hasImage()" :src="makeAvImg()"
                :size="size"
                fluid
                rounded="circle"
                :variant="makeAvVariant()"></b-img-lazy>
    <b-avatar :text="makeAvText()"
              v-else
              :size="size"
              :variant="makeAvVariant()"></b-avatar>
  </div>
</template>
<script>
export default {
  name: 'ProductAvatar',
  props: ['product', 'size'],
  methods: {
    makeAvText () {
      let c = this.product || {name: '', images: []}
      let t = c.name.split(' ').map(p => p.toUpperCase()[0]).filter((_, i) => i < 2).join('')
      return !c.images || c.images.length === 0 ? t : ''
    },
    makeAvImg () {
      let c = this.product || {name: '', images: []}
      if (!c.images || c.images.length === 0) {
        return ''
      } else {
        let firstImg = c.images[0];
        if (firstImg.startsWith("http")) {
          return firstImg
        }
        return `${process.env.VUE_APP_URL}/public/attachment?file=${firstImg}`
      }
    },
    hasImage () {
      let c = this.product || {name: '', images: []}
      return !(!c.images || c.images.length === 0);

    },
    makeAvVariant () {
      let indx = Math.ceil(Math.random() * 10)
      return ['primary', 'secondary', 'info', 'success', 'warning', 'dark', 'light'][indx % 7]
    }
  }
}
</script>