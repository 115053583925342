<template>
  <div>
    <b-form-input :size="size" class="mr-md-2 ml-md-2" placeholder="Search" v-model="search"
                  debounce="100"
                  @input="searchAll"></b-form-input>
    <div v-if="searchResult"
         @blur="searchResult = false"
         :style="css"
         class="search-results mt-3">

      <div class="list-group">
        <b-link class="list-group-item d-flex w-100" v-for="(sr, idx) of searchResults" :key="idx" :to="sr.link"
                @click="hideAndGo(sr)"
                style="text-decoration: none">
          <order :order="sr.item" v-if="sr.type === 'ORDER'"></order>
          <customer :customer="sr.item" v-if="sr.type === 'CUSTOMER'"></customer>
          <product :product="sr.item" v-if="sr.type === 'PRODUCT'"></product>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Customer from '@/components/search/Customer.vue'
import Product from '@/components/search/Product.vue'
import Order from '@/components/search/Order.vue'

export default {
  name: 'SearchForm',
  components: {Customer, Product, Order},
  props: {
    size: {
      default: 'sm',
      type: String
    },
    css: {
      default: `position: absolute;
  top: 50px;
  min-width: 20em;`,
      type: String
    }
  },
  data: function () {
    return {
      searchResult: null,
      search: '',
      orders: []
    }
  },
  computed: {

    searchResults() {
      if (this.search.length === 0) return []

      let c = this.$store.state.userRole === "ADMIN" ? this.$store.state.searchCustomers.map(cs => {
        return {
          type: 'CUSTOMER',
          item: cs,
          link: {
            name: 'customer-detail',
            params: {
              id: cs.id
            }
          }
        }
      }).slice(0, 4) : []

      let p = this.$store.state.products.filter(px => px.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
          .map(px => {


            return {
              type: 'PRODUCT',
              item: px,
              link: {
                name: 'product-view',
                params: {
                  id: px.id
                }
              }
            }
          }).slice(0, 4)

      return p.concat(c, this.orders)
    }
  },
  methods: {
    async hideAndGo() {
      setTimeout(() => {
        this.searchResult = false
        this.search = ''
        this.$emit('clicked')
      }, 100)
    },
    async searchAll() {
      //search customer, product, order #
      setTimeout(async () => {
        console.log('search', this.search, this.search.length, this.$store.state.userRole)
        if (this.search.length >= 3) {
          let isAdminUser = this.$store.state.userRole === "ADMIN";
          if (isAdminUser) {
            await this.$store.dispatch('searchCustomer', {search: this.search, area: null, brand: null})
          }

          let nmReg = /^\d+$/

          if (nmReg.test(this.search) && isAdminUser) {

            let json = await axios.get(
                `/api/admin/orders?orderNum=${this.search}`
            )

            this.orders = json.data.orders.map(o => {
              return {
                type: 'ORDER',
                item: o,
                link: {
                  name: 'order-view',
                  params: {
                    id: o.id
                  }
                }
              }
            })
          } else {
            this.orders = []
          }

          this.searchResult = true
        } else {
          this.searchResult = false
        }
      }, 300)
    }
  }
}
</script>

<style scoped>

</style>