import Vuex from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import {flatten} from 'lodash/array'
import {NewDeliveryArea, NewOrder, makeImgUrl} from '@/util'

let jwtDecode = require('jwt-decode')

Vue.use(Vuex)

let key = 'AuthToken'
axios.defaults.headers.common['Authorization'] = localStorage.getItem(key)
export default new Vuex.Store({
    state: {
        orderStatusMap: {
            'OPEN': 'primary',
            'DRAFT': 'warning',
            'CANCELLED': 'danger',
            'SCHEDULED': 'info',
            'DELIVERED': 'success',
            'WAITING_FOR_PICKUP': 'success',
            'SHIPPED': 'light',
        },
        order: NewOrder(NewDeliveryArea(), ''),
        user: {},
        threads: [],
        lastModifiedAt: 0,
        me: {deliveryArea: {daysOfDelivery: [], daysOfSubscription: []}},
        serverState: {},
        stateLoaded: false,
        masterLoaded: false,
        userRole: 'ANON',
        authToken: localStorage.getItem(key),
        isLoggedIn: localStorage.getItem(key) != null,
        deliveryInstructions: [],
        areaTypes: [],
        weekDays: [],
        minOrderValue: 0,
        orderCutOffDate: '',
        currentUser: {},
        currentCustomer: {},
        catSort: 'name',
        categories: [],
        products: [],
        materials: [],
        stores: [],
        apartments: [],
        taxPct: [],
        texture: [],
        allAreas: [],
        webp: false,
        customerSubscriptions: [],
        showCustomerMessageModal: false,
        showPaymentLinkModal: false,
        message: {customer: {deliveryArea: {}}},
        paymentOrder: {id: null, customer: {deliveryArea: {}}},
        orderClosesAt: '',
        orderTimeClosed: false,
        waStatus: false,
        googleProductCat: [],
        fbProductCat: [],
        roles: [
            {id: 'ADMIN', name: 'ADMIN'},
            {id: 'CUSTOMER', name: 'CUSTOMER'},
            {id: 'DELIVERY_PERSON', name: 'DELIVERY_PERSON'},
            {id: 'STORE_EMPLOYEE', name: 'STORE_EMPLOYEE'}
        ],
        wefast: {
            timeOfPickup: '16:30:00',
            dateOfPickup: '',
            total_weight_kg: '',
            points: []
        },
        msgScreen: {
            selectedCustomer: {id: null, name: '', profilePicUrl: '', deliveryArea: {name: ''}},
            customerId: null,
            messages: []
        },
        allTags: [],
        brands: [],
        collections: [],
        valueTypes: 'STR, NUM, LST, DATE, TIME, DATE_TIME'.split(',').map(m => m.trim()),
        searchCustomers: [],
        shiprocket: null,
        shipRocketOrder: NewOrder(NewDeliveryArea(), ''),
        orderUpdated: null,
        updatedOrder: {
            id: null,
            orderNum: null,
            status: null
        },
        editOrder: null,
        discounts: [],
        editAddress: null,
        editCustomer: null,
        addressUpdated: null,
        customerUpdated: null,
        appStatus: false,
        ingredients: [],
        editProductRecipe: null,
        productShare: null,
    },

    mutations: {
        setEditProductRecipe(state, s) {
            state.editProductRecipe = s
        },
        setProductShare(state, s) {
            state.productShare = s
        },
        setIngredients(state, s) {
            state.ingredients = s
        },
        setAppStatus(state, s) {
            state.appStatus = s
        },
        setTags(state, tags) {
            state.allTags = tags
        },
        saveAreas(state, area) {
            state.allAreas = area
        },
        saveDiscounts(state, discounts) {
            state.discounts = discounts
        },
        editAddress(state, {id}) {
            state.editAddress = `${id}:${new Date().getTime()}`
        },
        editCustomer(state, json) {
            state.editCustomer = json
        },
        addressUpdated(state, {id}) {
            state.addressUpdated = `${id}:${new Date().getTime()}`
        },
        customerUpdated(state, {id}) {
            state.customerUpdated = `${id}:${new Date().getTime()}`
        },
        editOrder(state, order) {
            let eo = order.id || `NEW`
            state.order = order
            //to force change detection
            state.editOrder = `${eo}${new Date().getTime()}`
        },
        orderUpdated(state, {id, orderNum, status}) {
            state.orderUpdated = id
            Vue.set(state.updatedOrder, 'id', id)
            Vue.set(state.updatedOrder, 'orderNum', orderNum)
            Vue.set(state.updatedOrder, 'status', status)
        },
        shiprocket(state, order) {
            state.shipRocketOrder = order
            //to force change detection
            state.shiprocket = `${order.id}${new Date().getTime()}`
        },
        updateReviewStatus(state, {id, done}) {
            state.searchCustomers.forEach(c => {
                if (c.id === id) {
                    c.reviewDone = done
                }
            })
        },
        selectCustomerForMsg(state, {customer, id, messages}) {
            state.msgScreen = {
                selectedCustomer: customer,
                customerId: id,
                messages: messages
            }
        },
        createWF(state, {points, time, date}) {
            state.wefast = {
                timeOfPickup: time,
                total_weight_kg: '',
                points: points,
                dateOfPickup: date
            }
        },
        updateTimeOfPickup(state, timeOfPickup) {
            console.log('time of pickup', timeOfPickup)
            state.wefast.timeOfPickup = timeOfPickup
        },
        updateDateOfPickup(state, dateOfPickup) {
            console.log('date of pickup', dateOfPickup)
            state.wefast.dateOfPickup = dateOfPickup
        },
        updatePoint(state, point) {
            state.wefast.points.forEach(p => {
                if (p.id === point.id) {
                    p.weight = point.weight
                    p.address = point.address
                    p.latitude = point.latitude
                    p.longitude = point.longitude
                    p.apartment_number = point.apartment_number
                    p.address = point.address
                }
            })
        },
        UpdateWaStatus(state, st) {
            state.waStatus = st
        },
        hideCustomerMessageModal(state) {
            state.showCustomerMessageModal = false
            state.showPaymentLinkModal = false
        },
        updateThreads(state, threads) {
            console.log('lastModified', 'from', state.lastModifiedAt, '-->', 'to', threads.lastModified)
            try {
                state.threads = threads.threads
                state.lastModifiedAt = threads.lastModified
            } catch (e) {
                console.error('error in state', state, threads)
            }
        },
        showCustomerMessageModal(state, {customer, brand}) {
            state.showCustomerMessageModal = true
            state.message.customer = customer
            state.message.brand = brand
        },
        showPaymentLinkModal(state, order) {
            state.showPaymentLinkModal = true
            state.paymentOrder = order
        },
        saveState(state, cfg) {
            state.serverState = cfg
            state.deliveryInstructions = cfg.deliveryInstructions
            state.customerSubscriptions = cfg.customerSubscriptions
            state.areaTypes = cfg.areaTypes
            state.minOrderValue = cfg.minOrderValue
            state.currentCustomer = cfg.currentCustomer
            state.currentUser = cfg.currentUser
            state.weekDays = cfg.weekDays
            state.stores = cfg.stores
            state.apartments = cfg.apartments
            state.orderCutOffDate = cfg.orderCutOffDate
            state.texture = cfg.texture
            state.unit = cfg.unit
            state.stateLoaded = true
            state.allAreas = flatten([cfg.stores, cfg.apartments])
        },
        saveUser(state, user) {
            state.user = user
            localStorage.setItem('USER', JSON.stringify(user))
        },
        saveCategories(state, cat) {
            state.categories = cat
        },
        saveCollections(state, collections){
          state.collections = collections
        },
        saveProducts(state, products) {
            state.products = products.map(p => {
                p.imagesToDisplay = (p.images || []).map(pi => makeImgUrl(pi))
                p.videos = p.videos || []
                p.variants = p.variants.map(v => {
                    let v1 = Object.assign({}, v)
                    v1.productId = p.id
                    v1.displayName = Object.keys(v.matrix)
                        .map(k => {
                            return `${k} = ${v.matrix[k]}`
                        })
                        .join(', ')
                    return v1
                })
                return p
            })
        },
        saveTaxes(state, tax) {
            state.taxPct = tax
        },
        saveMe(state, me) {
            state.me = me
        },
        saveBrands(state, me) {
            state.brands = me
        },
        loggedOut(state) {
            state.isLoggedIn = false
            state.accessToken = null
            state.user = {}
            state.userRole = 'ANON'
            localStorage.removeItem(key)
            axios.defaults.headers.common['Authorization'] = null
        },
        loggedIn(state, at) {
            state.authToken = at
            state.isLoggedIn = true
            let decoded = jwtDecode(at)
            console.log('jwt = ', decoded)
            state.mobile = decoded['mobile']
            state.userRole = decoded['role']

            axios.defaults.headers.common['Authorization'] = at
            localStorage.setItem(key, at)
        },
        catSort(state, catSort) {
            state.catSort = catSort
        },
        saveCat(state, {what, data}) {
            if (what === 'google') state.googleProductCat = data
            else state.fbProductCat = data
        },
        dndUpdate(state, {mobile, dnd}) {
            state.threads
                .filter(t => t.customer.mobile === mobile)
                .forEach(t => {
                    console.log('update thread, for', mobile, 'dnd = ', dnd, 'from = ', t.dnd)
                    t.dnd = dnd
                })
        },
        hasWebP(state, h) {
            state.webp = h
            console.log('web?', h)
            let cl = document.getElementsByTagName('body')[0].classList
            if (h) {
                cl.add('webp')
                cl.remove('no-webp')
            } else {
                cl.add('no-webp')
                cl.remove('webp')
            }
        },
        saveCustomerSearch(state, customers) {
            state.searchCustomers = customers
        },
        loadCustomerSearch(state, customer) {
            console.log('set load customer', customer)
            customer.displayName = customer.name
            state.searchCustomers = [customer]
        }
    },
    actions: {
        async setEditProductRecipe(context, {id}){
          context.commit('setEditProductRecipe', id)
        },
        async setProductShare(context, {id}){
          context.commit('setProductShare', id)
        },
        async loadIngredients(context) {
            let ings = (await axios.get("/api/admin/recipe/ingredients")).data
            context.commit('setIngredients', ings)
        },
        async searchCustomer(context, {search, area, brand}) {
            console.log('searchCustomer', search, area, brand)
            let r = await axios.get('/api/admin/customers?search=' + search + '&store=' + area + '&brand=' + brand, {
                headers: {
                    'is-search': true
                }
            })
            context.commit('saveCustomerSearch', r.data)
        },
        async loadSelectedCustomer(context, id) {
            console.log('loadingCustomer', id)
            let cj = await axios.get(`/api/admin/customers/${id}`)
            let sl = cj.data
            context.commit('loadCustomerSearch', sl)
        },
        async loadSelectedCustomerMessage(context, id) {
            let cj = await axios.get(`/api/admin/customers/${id}`)
            let sl = cj.data

            let json = await axios.get(`/api/admin/message/customer/${id}`)
            let messages = json.data
            context.commit('selectCustomerForMsg', {customer: sl, id: id, messages: messages})
            document.getElementById('contents-msg').scrollIntoView()
        },
        async loadThreads(context) {
            let r = await axios.get(`/api/admin/message/thread?after=${this.state.lastModifiedAt}`)
            if (r.status === 200) {
                context.commit('updateThreads', r.data)
            } else {
                console.log('no updates')
            }
        },
        async sendMessage(context, {customer, brand}) {
            console.log(`asking to send message to ${customer} from ${brand}`)
            context.commit('showCustomerMessageModal', {customer: customer, brand: brand})
        },
        async sendPaymentLink(context, order) {
            console.log(`asking to send message to ${order.customer.name}`)
            context.commit('showPaymentLinkModal', order)
        },
        async hideMessageModal(context) {
            context.commit('hideCustomerMessageModal')
        },
        async loadProducts(context) {
            let res = await axios.get('/api/products')
            context.commit('saveProducts', res.data)
        },
        async loadCollections(context) {
            let resC = await axios.get('/api/admin/collections')
            context.commit('saveCollections', resC.data)
        },
        async loadCategories(context, catSort) {
            let res = await axios.get(`/api/categories?order=${catSort}`)
            context.commit('saveCategories', res.data)
        },
        async loadTaxPct(context) {
            let res = await axios.get('/api/admin/taxes')
            context.commit('saveTaxes', res.data)
        },
        async loadME(context) {
            let resp = await axios.get('/api/me')
            context.commit('saveMe', resp.data)
        },
        async loadBrands(context, whom) {
            let resp = await axios.get(`/api/${whom || 'admin'}/brands`)
            context.commit('saveBrands', resp.data)
        },
        async loadState(context) {

            let resp = await axios.get('/api/state')
            context.commit('saveState', resp.data)

        },
        async appLoadComplete(context) {
            context.commit('setAppStatus', true)
        },
        async checkUserLogin(context) {
            let at = localStorage.getItem(key)
            if (at == null) {
                throw Error('AuthToken Not Found')
            } else {
                let cfg = {
                    headers: {
                        Authorization: at
                    }
                }
                let resp = axios.get('/api/me', cfg)
                context.commit('loggedIn', at)
                context.commit('saveUser', resp.data)
            }
        },
        async supportsWebp(context) {
            if (!self.createImageBitmap) return false

            const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA='
            const blob = await fetch(webpData).then(r => r.blob())
            return createImageBitmap(blob).then(() => context.commit('hasWebP', true), () => context.commit('hasWebP', false))
        },
        async loadProdCat(context) {
            let g = await axios.get('/public/social-product-id?q=google')
            let f = await axios.get('/public/social-product-id?q=facebook')
            await context.commit('saveCat', {what: 'google', 'data': g.data})
            await context.commit('saveCat', {what: 'facebook', 'data': f.data})
        },
        async toggleDnD(context, {id, dnd}) {
            await context.commit('dndUpdate', {mobile: id, dnd: dnd})
        },
        async loadProductTags(context) {
            let allTags = (await axios.get('/api/admin/products/tags')).data
            await context.commit('setTags', allTags)
        },
        async updateReviewStatus(context, {id, done}) {
            await context.commit('updateReviewStatus', {id, done})
        },
        async orderShiprocket(context, {id}) {
            let json = await axios(`/api/admin/orders/${id}`)
            let order = json.data
            await context.commit('shiprocket', order)
        },
        async editOrder(context, {id}) {
            let json = await axios(`/api/admin/orders/${id}`)
            let order = json.data
            await context.commit('editOrder', order)
        },
        async orderUpdated(context, {id, orderNum, status}) {
            await context.commit('orderUpdated', {id, orderNum, status})
        },
        async loadDiscounts(context) {
            let pds = await axios.get(`/api/admin/discount`)
            await context.commit('saveDiscounts', pds.data)
        },
        async loadAreas(context) {
            let pds = await axios.get(`/api/admin/areas`)
            await context.commit('saveAreas', pds.data)
        },
        async createOrder(context, newOrder) {
            await context.commit('editOrder', newOrder)
        },
        async editAddress(context, {id}) {
            await context.commit('editAddress', {id})
        },
        async editCustomer(context, json) {
            console.log('editCustomer', json)
            await context.commit('editCustomer', json)
        },
        async addressUpdated(context, {id}) {
            await context.commit('addressUpdated', {id})
        },
        async customerUpdated(context, {id}) {
            await context.commit('customerUpdated', {id})
        }
    }
})