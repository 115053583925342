<template>
  <b-modal id="product-share" ok-only @ok="reallyShare"
           @hidden="resetShare" :title="'Share '+product.name">
    <div
        class="d-flex flex-column justify-content-center align-items-center align-content-center p-3 bg-bw">
      <div class="d-flex flex-column flex-md-wrap  flex-md-row justify-content-center">
        <div class="my-2 mx-2">
          <b-form-checkbox
              id="checkbox-1"
              v-model="showDesc"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
          >
            Share Description
          </b-form-checkbox>
        </div>
        <div class="my-2 mx-2" v-if="product.ingredients.length > 0">
          <b-form-checkbox
              id="checkbox-2"
              v-model="showIng"
              name="checkbox-2"
              :value="true"
              :unchecked-value="false"
          >
            Share Ingredients
          </b-form-checkbox>
        </div>
        <div class="my-2 mx-2">
          <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="selected"
              :options="activeVariants"
              text-field="name"
              value-field="id"
              name="flavour-1"
          ></b-form-checkbox-group>

        </div>
      </div>

      <div ref="share"
           class="d-flex flex-column justify-content-center align-items-center align-content-center p-3 bg-bw">

        <img v-if="product.images.length > 0" :src="product.images[0]"
             alt="product image"
             style="border-radius: 10px">

        <h4 class="my-3 bg-bw text-center">{{ product.name }}</h4>
        <p v-if="showDesc" class="bg-bw my-3" v-html="product.description"></p>
        <p v-if="showIng && product.ingredients.length > 0"><strong>Ingredients:</strong>
          {{ product.ingredients.join(', ') }}</p>

        <div class="bg-bw list-group list-group-flush w-100 mt-3">
          <div class="bg-bw list-group-item">
            <strong>Price</strong>
          </div>
          <div class="bg-bw list-group-item d-flex justify-content-between" v-for="px of selectedVariants"
               :key="px.id">
            <span>{{ px.name }}</span>
            <span><rupee>{{ px.offerPrice || px.price }}</rupee></span>
          </div>
        </div>
      </div>


    </div>
  </b-modal>

</template>
<script>
import {AddProduct} from "@/util";
import {toPng} from "html-to-image";
import Rupee from "@/components/rupee.vue";
export default {
  name: 'ProductShare',
  components:{Rupee},
  data: () => ({
    product: AddProduct(),
    activeVariants: [],
    selectedVariants: [],
    showDesc: true,
    showIng: true,
    selected: [],
  }),
  methods: {
    async shareFile(file, title, text) {
      if (navigator.canShare && navigator.canShare({files: [file]})) {
        await navigator
            .share({
              files: [file],
              title,
              text
            })
      } else {
        console.log(`Your system doesn't support sharing files.`)
      }
      await this.resetShare()
    },
    async resetShare(){
      console.log('closed share dialog')
      await this.$store.dispatch('setProductShare', {id: null})
    },
    dataURLtoFile(dataUrl, filename) {
      let arr = dataUrl.split(','),
          mimeType = arr[0].match(/:(.*?);/)[1],
          decodedData = atob(arr[1]),
          lengthOfDecodedData = decodedData.length,
          u8array = new Uint8Array(lengthOfDecodedData)
      while (lengthOfDecodedData--) {
        u8array[lengthOfDecodedData] = decodedData.charCodeAt(lengthOfDecodedData)
      }
      return new File([u8array], filename, {type: mimeType})
    },
    async shareProduct(p) {
      console.log('share product', p)
      this.product = p
      this.activeVariants = p.variants.filter(pv => pv.active)
      this.selectedVariants = this.activeVariants
      await this.$bvModal.show(`product-share`)
    },
    async reallyShare() {
      let dataUrl = await toPng(this.$refs.share)
      const file = this.dataURLtoFile(dataUrl, `${this.product.name}.png`)
      await this.shareFile(file, this.product.name, `https://themilletstore.in/product/${this.product.slug}`)
    },
  },
  watch: {
    async productShare(productId) {

      if (productId) {
        let product = this.$store.state.products.find(p => p.id === productId)
        if (product) {
          await this.shareProduct(product)
        }
      }
    }
  },
  computed: {
    title() {
      return `Share ${this.product.name}`
    },
    productShare() {
      return this.$store.state.productShare
    }
  },
}
</script>