<template>
  <div id="app">
    <div class="app-content">
      <app-header v-if="$store.state.isLoggedIn"></app-header>

      <div class="container-fluid mt-3 p-1 p-md-3" style="min-height: 85vh">
        <router-view></router-view>
      </div>

      <b-modal id="modal-message" title="Send Message to Customer" @ok="sendMessageToCustomer"
               v-model="$store.state.showCustomerMessageModal">
        <div class="list-group">
          <div class="list-group-item">
            To: {{ $store.state.message.customer.name }} ({{ $store.state.message.customer.mobile }})
          </div>
          <div class="list-group-item">
            <b-form-select v-model="brandId" :options="$store.state.brands" value-field="id"
                           text-field="name"></b-form-select>
          </div>
          <div class="list-group-item">
            <b-form-textarea v-model="mesg" placeholder="Message" rows="8"></b-form-textarea>
          </div>
        </div>

      </b-modal>
      <b-modal id="modal-payment" title="Send Payment Link Customer"
               hide-footer
               v-model="$store.state.showPaymentLinkModal">
        <div class="list-group">
          <div class="list-group-item">
            To: {{ $store.state.paymentOrder.customer.name }} ({{ $store.state.paymentOrder.customer.mobile }}) <br/>
            {{ $store.state.paymentOrder.customer.house }} {{ $store.state.paymentOrder.customer.deliveryArea.name }}
          </div>
          <div class="list-group-item d-flex justify-content-center">
            <b-button-group size="lg">
              <b-button @click="sendPayLink(true)">
                UPI Link
              </b-button>
              <b-button @click="sendPayLink(false)" variant="success">
                Credit Card Link
              </b-button>
            </b-button-group>
          </div>
        </div>

      </b-modal>

      <shiprocket-ship></shiprocket-ship>
      <order-edit></order-edit>
      <address-edit></address-edit>
      <customer-edit></customer-edit>
      <product-recipe></product-recipe>
      <product-share></product-share>
    </div>

    <div class="fab d-md-none" :style="visibleIfScroll">
      <a href="" @click.prevent="upPage" style="color: white">
        <font-awesome-icon :icon="['fas', 'caret-up']"></font-awesome-icon>
      </a>
    </div>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader'
import axios from 'axios'
import ShiprocketShip from '@/components/others/ShiprocketShip'
import OrderEdit from '@/components/others/OrderEdit'
import AddressEdit from '@/components/others/AddressEdit'
import CustomerEdit from '@/components/others/CustomerEdit'
import ProductRecipe from "@/components/others/ProductRecipe.vue";
import ProductShare from "@/components/others/ProductShare.vue";

export default {
  name: 'App',
  components: {ProductShare, ProductRecipe, CustomerEdit, AddressEdit, OrderEdit, ShiprocketShip, AppHeader},
  async created () {

    window.addEventListener('scroll', this.handleScroll)
    await this.$store.dispatch('supportsWebp')

    try {
      const FIVE_MIN = 15 * 60 * 1000
      setInterval(async () => {
        await this.checkRefresh()
      }, FIVE_MIN)

      setTimeout(() => {
        if (!this.isAdmin) {
          console.log('not admin, no sse')
          return
        }

        let sseUrl = process.env.VUE_APP_URL + '/threads'
        console.log(`connect sse ${sseUrl}`)

        this.$sse.create(sseUrl)
            .on('message', async (msg) => {
              if (msg.threads && msg.threads.length > 0) {
                await this.$store.commit('updateThreads', msg)
              }
            })
            .on('error', async (err) => {
              console.error('Failed to parse or lost connection:', err)
            })
            .connect()
            .catch((err) => {
              console.error('Failed make initial connection:', err)
            })
      }, 10 * 1000)

      if (this.$router.currentRoute.path !== '/login' && this.$router.currentRoute.path !== '/register') {
        try {
          await this.$store.dispatch('checkUserLogin')
        } catch (e) {
          console.log(`user check failed`, this.$router.currentRoute, e)
          await this.redirectToLogin()
          return
        }

        await this.$store.dispatch('loadState')

        await this.$store.dispatch('loadCategories')
        await this.$store.dispatch('loadProducts')
        await this.$store.dispatch('loadME')
        await this.$store.dispatch('loadBrands')
        await this.$store.dispatch('appLoadComplete')

        if (this.$store.state.userRole === 'DELIVERY_PERSON' && this.$router.currentRoute.path !== '/delivery/orders') {
          await this.$router.push('/delivery/orders')
        }

        if (this.$store.state.userRole === 'ADMIN') {
          await this.$store.dispatch('loadTaxPct')
          if (this.$router.currentRoute.path === '/') {
            await this.$router.push('/admin/dashboard')
          }
        }
        await this.checkRefresh()

      } else {
        await this.redirectToLogin()
      }
    } catch (e) {
      console.error(`error in loading data [${e.message}]`, e)
      //this.redirectToLogin()
    }
  },
  computed: {
    isCustomer: function () {
      return this.$store.state.userRole === 'CUSTOMER'
    },
    isAdmin: function () {
      return this.$store.state.userRole === 'ADMIN'
    },
    orderUpdated () {
      return this.$store.state.orderUpdated
    }
  },
  data: () => ({
    mesg: '',
    brandId: '',
    scrollPosition: 0,
    visibleIfScroll: 'visibility: visible'
  }),
  watch: {
    orderUpdated () {
      this.notifyOrderStatus(this.$store.state.updatedOrder)
    }
  },
  methods: {
    notifyOrderStatus ({orderNum, status}) {
      this.$bvToast.toast(`Order #${orderNum} Updated to ${status}`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'success'
      })
    },
    handleScroll () {
      this.scrollPosition = window.scrollY
      let b = window.scrollY > window.innerHeight / 2
      if (b) {
        this.visibleIfScroll = 'visibility: visible'
      } else {
        this.visibleIfScroll = 'visibility: hidden'
      }
    },
    upPage () {
      document.getElementById('app').scrollIntoView(
          {behavior: 'smooth', block: 'start', inline: 'start'}
      )
    },
    async requestNotificationPermission () {
      console.log('chcking for notification permission')
      const permission = await window.Notification.requestPermission()
      // value of permission can be 'granted', 'default', 'denied'
      // granted: user has accepted the request
      // default: user has dismissed the notification permission popup by clicking on x
      // denied: user has denied the request.
      if (permission !== 'granted') {
        throw new Error('Permission not granted for Notification')
      }
    },
    async checkRefresh () {
      try {
        let fr = await axios.get('/api/force-refresh')
        let key = fr.data.key
        let item = localStorage.getItem(key)
        console.log('force refresh?', key, ' -> ', item)
        if (!item) {
          localStorage.setItem(key, 'DONE')
          window.location.reload(true)
        }
      } catch (e) {
        console.log('error in refresh', e)
      }
    },

    async sendMessageToCustomer () {
      if (!this.brandId) {
        alert('select brand')
        return
      }
      await axios.post(`/api/admin/message?brand_id=${this.brandId}`, {
        customerId: this.$store.state.message.customer.id,
        mesg: this.mesg
      })
      this.mesg = ''
      await this.$store.dispatch('hideMessageModal')

    },
    async sendPayLink (upi) {
      await axios.post(`/api/admin/orders/payment?id=${this.$store.state.paymentOrder.id}&upi=${upi}`)
      await this.$store.dispatch('hideMessageModal')

    },
    async redirectToLogin () {
      await this.$store.commit('loggedOut')
      if (this.$router.currentRoute.path !== '/login' && this.$router.currentRoute.path !== '/register') {
        await this.$router.push('/login')
      }
    }
  }
}
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: contain;
}


@media (min-width: 481px) {

  .app-content {
    padding-bottom: 90px;
  }

  .md-w-25 {
    width: 23% !important;
  }

}

@media (min-width: 320px) and (max-width: 480px) {

  .app-content {
    padding-bottom: 120px;
  }

  .m-w-100 {
    width: 100% !important;
  }
}

.bottom-bar > .navbar {

}

.bottom-bar .nav-item {
  border: 1px solid #cccccc;
  margin: 3px;
}

.bg-mild-warning {
  background-color: orange !important;
}

.app-header.sticky-top {
}

.b-sidebar {
}

.b-sidebar-outer {
  bottom: 0 !important;
  top: 100% !important;
}

#app .card-body {
  padding: .75rem;
}

.dark img {
  filter: brightness(.8) contrast(1.2)
}

.dark .text-toggle {
  color: #aaa;
}

.light .text-toggle {
  color: #444;
}
</style>
