/* eslint-disable no-console */

import {register} from 'register-service-worker'

function sendSubscriptionToServer(subscription) {

    // Get public key and user auth from the subscription object
    var key = subscription.getKey ? subscription.getKey('p256dh') : '';
    var auth = subscription.getKey ? subscription.getKey('auth') : '';

    // This example uses the new fetch API. This is not supported in all
    // browsers yet.
    return fetch(`${process.env.VUE_APP_URL}/api/profile/subscription`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AuthToken')
        },
        body: JSON.stringify({
            endpoint: subscription.endpoint,
            // Take byte[] and turn it into a base64 encoded string suitable for
            // POSTing to a server over HTTP
            key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '',
            auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : ''
        })
    });
}
const pubKeu = new Uint8Array([
    0x04,0x6c,0xc8,0xe6,0x5e,0x5b,0xc6,0xb7,0xf2,0x01,0xf3,0xed,0xb2,0x2b,0x5f,0xdb,0x6a,0xd5,0x65,0xef,0xc2,0xc9,0x86,0xc3,0xab,0x03,0x0a,0xf8,0x12,0x26,0xba,0xc9,0x0d,0x8d,0xb2,0x90,0xb8,0xa9,0x05,0x9b,0xf1,0x34,0x2a,0x82,0x4e,0x38,0x6c,0x0c,0x31,0x08,0xb8,0x31,0x9b,0xfb,0x20,0xcb,0x31,0xbf,0xe9,0xc5,0xc4,0x9a,0x58,0xa0,0xa9
]);
if (process.env.NODE_ENV === 'production') {

    register(`${process.env.BASE_URL}service-worker.js`, {
        async ready(registration) {

            if ('PushManager' in window && localStorage.getItem("AuthToken")) {
                try {
                    const options = {
                        userVisibleOnly: true,
                        applicationServerKey: pubKeu
                    }
                    const existing = await registration.pushManager.getSubscription()
                    if (existing == null) {
                        const subscription = await registration.pushManager.subscribe(options)
                        console.log(JSON.stringify(subscription))
                        await sendSubscriptionToServer(subscription)
                    } else {
                        console.log("found an existing subscription", existing)
                    }
                } catch (err) {
                    console.log('Error', err)
                }
            }
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is downloading.')
        },
        updated() {
            console.log('New content is available; please refresh.')
            setTimeout(() => {
                window.location.reload(true)
            }, 3000)
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
