<template>
  <b-modal id="modal-shiprocket" title="Book Shiprocket" hide-footer size="xl">
    <h2>Order</h2>
    <b-form-group label="Customer">
      <b-form-input v-if="order.address.person" v-model="order.address.person" disabled></b-form-input>
      <b-form-input v-else v-model="order.customer.name" disabled></b-form-input>
    </b-form-group>
    <b-form-group label="Address">
      <b-form-input v-model="order.address.address" disabled></b-form-input>
    </b-form-group>

    <b-input-group prepend="Box Dimensions (in cm)" class="mb-2">
      <b-form-input v-model="cc.l" type="number" placeholder="Length"></b-form-input>
      <b-form-input v-model="cc.b" type="number" placeholder="Breath"></b-form-input>
      <b-form-input v-model="cc.h" type="number" placeholder="Height"></b-form-input>
    </b-input-group>
    <b-input-group prepend="Box Weight (in KG)" class="mb-2">
      <b-form-input v-model="cc.w" type="number" step="any" placeholder="Weight"></b-form-input>
    </b-input-group>

    <b-input-group prepend="Locations" class="mb-2">
      <b-form-input v-model="cc.from" placeholder="From Pincode"></b-form-input>
      <b-form-input v-model="cc.to" placeholder="To Pincode"></b-form-input>
    </b-input-group>
    <div class="d-flex justify-content-end">
      <b-button @click="searchCourier" variant="primary">
        Search Courier
      </b-button>

    </div>
    <div class="d-flex flex-row flex-wrap justify-content-center mt-3" v-if="couriers.length > 0">

      <div class="card mr-2 mt-2" v-for="(c, idx) of couriers" :key="idx">
        <div class="card-header">{{ c.name }}</div>
        <div class="card-body">
          <rupee>{{ c.amt }}</rupee>
          <div class="d-block">
            <font-awesome-icon icon="calendar"></font-awesome-icon>
            {{ c.date }}
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <b-button @click="bookCourier(c)" variant="success">
            Book
          </b-button>
        </div>
      </div>
    </div>

  </b-modal>
</template>

<script>
import axios from 'axios'
import Rupee from '@/components/rupee'

export default {
  name: 'ShiprocketShip',
  components: {Rupee},
  data () {
    return {

      cc: {
        from: '',
        to: '',
        l: 0,
        b: 0,
        h: 0,
        w: 0.0
      },
      couriers: [],
    }
  },
  computed: {
    order () {
      return this.$store.state.shipRocketOrder
    },
    shiprocket () {
      return this.$store.state.shiprocket
    }
  },
  watch: {
    async shiprocket () {
      await this.shiprocketShip()
    }
  },
  methods: {

    async shiprocketShip () {
      this.cc.from = this.order.fromPincode
      this.cc.to = this.order.address.pincode
      this.couriers = []
      this.$bvModal.show('modal-shiprocket')
    },
    async searchCourier () {
      let v = this.cc.l * this.cc.b * this.cc.h
      if (v > 0 && this.cc.w > 0) {
        this.couriers = (await axios.post('/api/shiprocket/couriers', this.cc)).data
      } else {
        this.$bvToast.toast('Need Weight and LxBxH', {
          title: 'Oops',
          autoHideDelay: 5000,
          variant: 'info',
          solid: true,
          appendToast: false
        })
      }
    },
    async bookCourier (c) {
      let c0 = Object.assign({}, c)
      c0.orderId = this.order.id
      await axios.post('/api/shiprocket/book', {
        courier: c0,
        check: this.cc
      })
      setTimeout(() => {
        window.open(process.env.VUE_APP_URL + '/public/shiprocket-label/' + this.order.id)
      }, 500)

      this.$bvModal.hide('modal-shiprocket')
      await this.$store.dispatch('orderUpdated', {id: this.order.id, status: 'ShipRocket Booked', orderNum: this.order.orderNum})
    },
  }
}
</script>

<style scoped>

</style>