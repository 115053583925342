<template functional>
  <div class="d-flex align-items-center align-content-center">
    <product-avatar :product="props.product" size="2em"></product-avatar>
    <div class="d-flex flex-column ml-2">
      <strong>
        {{ props.product.name }}
      </strong>
      <ul class="list-unstyled">
        <li v-for="px of props.product.variants" :key="px.id">
          <div class="d-flex justify-content-between">
            {{ px.name }}
            <span>
            <s>&#8377; {{ px.price }}</s> -
            <span class="ml-2"> &#8377; {{ px.offerPrice || px.price }}</span>
          </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  functional: true,
  name: 'Product',
  props: {
    product: Object
  }
}
</script>