<template id="hello-world">
  <div class="wait">
    <img src="/logo.svg" alt="">
  </div>
</template>
<script>
import { loading } from '@/util'

export default {
  name: 'HelloWorld',
  created: function () {
    loading(true)

    this.$store.dispatch('checkUserLogin')
        .then(() => {
          let role = this.$store.state.userRole
          setTimeout(() => {
            console.log('logged in')
            loading(false)
             if (role === 'AGENT') {
              this.$router.push('/agent/orders')
            } else if (role === 'STORE_EMPLOYEE') {
              this.$router.push('/store/orders')
            } else if (role === 'ADMIN') {
              if (this.$router.currentRoute.path === '/') {
                this.$router.push('/admin/dashboard')
              }
            }

          }, 1000)

        }, () => {
          loading(false)
        })

  }
}
</script>
<style>
.wait {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
  min-height: 100vh;
  width: 100%;
}
</style>