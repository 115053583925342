<template functional>
  <div class="">
    #{{props.order.orderNum}} - {{props.order.customer.name}} <br />
    {{props.order.orderStatus}}
  </div>
</template>
<script>
export default {
  functional: true,
  name: 'Order',
  props: {
    order: Object
  }
}
</script>