<template>
  <b-modal id="modal-cust-new"
           ref="m"
           title="New Customer" @ok="save" no-close-on-backdrop
           :ok-disabled="!newCustomer.brand.id || !newCustomer.mobile">
    <b-form>
      <b-form-group label="Name of Customer">
        <b-form-input v-model="newCustomer.name" type="text" placeholder="Name" :lazy="true"></b-form-input>
      </b-form-group>

      <b-form-group label="Mobile #">
        <b-form-input :disabled="newCustomer.id != null" v-model="newCustomer.mobile" type="tel"
                      placeholder="Mobile #" :lazy="true"></b-form-input>
      </b-form-group>

      <b-form-group label="Delivery Area">
        <v-select v-model="newCustomer.deliveryArea.id" :options="$store.state.allAreas"
                  :reduce="p =>p.id"
                  label="displayName"></v-select>
      </b-form-group>

      <b-form-group label="Brand">
        <b-form-select v-model="newCustomer.brand.id"
                       :options="$store.state.brands"
                       value-field="id" text-field="name"></b-form-select>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import axios from 'axios'
import { NewCustomer } from '@/util'

export default {
  name: 'CustomerEdit',
  data: () => ({
    newCustomer: NewCustomer()
  }),

  watch: {
    async editCust (customerId, oc) {

      console.log('editCustomer 3', customerId, oc)

      let idx = JSON.parse(customerId)
      let id0 = idx.id
      if (id0 === 'NEW') {

        this.newCustomer = NewCustomer()
        this.newCustomer.mobile = idx.mobile

      } else {
        let json = await axios.get(`/api/admin/customers/${id0}`)
        this.newCustomer = json.data

      }

      await this.$bvModal.show('modal-cust-new')
    }
  },
  computed: {
    title () {
      return `Edit Addresses for ${this.newCustomer.name}`
    },
    editCust () {
      return this.$store.state.editCustomer
    }
  },
  methods: {

    async save () {
      let newC = await axios.post('/api/admin/customers', this.newCustomer)
      this.newCustomer = NewCustomer()
      await this.$store.dispatch('customerUpdated', {id: newC.data.id})
    },
  }
}
</script>

<style scoped>

</style>