<template id="logout">
    <div class="logout d-flex justify-content-center">
        <h4>Logging you out ...</h4>
    </div>
</template>
<script>
    export default {
        name: "logout",
        data: () => {
            return {
                status: 'INIT',
                mobile: '',
                otp: ''
            }
        },
        created: function () {
            //hack to add center align for login page only
            this.$store.commit("loggedOut")
            setTimeout(() => {
                this.$router.push("/login")
            }, 500)
        },
        methods: {}
    }

</script>
<style scoped>
    .logout {
        min-width: 100vh;
        justify-content: center;
        align-content: center;
        align-items: center;
        align-self: center;
    }
</style>